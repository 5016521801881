<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import AddUserFeedbackCard from '@/infrastructure/userFeedbacks/components/AddUserFeedbackCard'
import FeedbackCardForCreator from '@/infrastructure/userFeedbacks/components/FeedbackCardForCreator'

export default {
  components: {
    AddUserFeedbackCard,
    FeedbackCardForCreator,
  },

  setup() {
    const { isBusy: isLoading, execute: fetchUserFeedbacks } = useAsyncLoading({
      actionFactory: () => store.dispatch('userFeedbacks/fetchUserFeedbacksForCreator'),
    })

    onMounted(fetchUserFeedbacks)

    return {
      isLoading,
    }
  },

  computed: {
    userFeedbacks: () => store.state.userFeedbacks.userFeedbacksForCreator,
  },
}
</script>

<template>
  <div>
    <v-card v-if="isLoading" class="mb-3">
      <v-card-text>
        <v-progress-linear indeterminate />
      </v-card-text>
    </v-card>

    <v-card v-else-if="!isLoading && (!userFeedbacks || userFeedbacks.length === 0)" class="mb-3">
      <v-card-text>No feedback yet</v-card-text>
    </v-card>

    <FeedbackCardForCreator
      v-for="feedback in userFeedbacks"
      :key="feedback.id"
      :created-on="feedback.createdOn"
      :message="feedback.message"
      :replies="feedback.replies"
      class="mb-3"
    />

    <AddUserFeedbackCard />
  </div>
</template>
